var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"grid-list-md":"","pa-0":"","relative":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":""}},[_c('v-card',{staticClass:"primary--border",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"title"},[_vm._v(" Subject Marks "),_c('v-spacer')],1),_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"title pb-0"},[_c('v-flex',{attrs:{"xs3":"","sm4":""}},[_c('v-select',{staticClass:"pa-0",attrs:{"items":_vm.exams,"label":"Exam","outlined":"","dense":""},model:{value:(_vm.exam),callback:function ($$v) {_vm.exam=$$v},expression:"exam"}})],1),_c('v-flex',{attrs:{"xs3":"","sm4":""}},[_c('v-select',{staticClass:"pa-0",attrs:{"loading":_vm.gradeLoading,"disabled":!_vm.exam,"items":_vm.grades,"persistent-hint":"","hint":_vm.selectedEvaluation,"label":"Grade","outlined":"","dense":""},model:{value:(_vm.grade),callback:function ($$v) {_vm.grade=$$v},expression:"grade"}})],1)],1)],1),_c('v-card-title',[(_vm.is_publish && !_vm.examPublishAlert)?_c('alert-message',{attrs:{"type":"error","title":"Exam published","message":"Exam has already been published and hence any sort of data modification is strictly prohibited."}}):_vm._e()],1),_c('div',{staticClass:"overlay attendance"},[_c('div',{staticClass:"data-represent",staticStyle:{"float":"right","display":"block","margin-top":"-20px"}},[_c('div',[_c('span',{staticClass:"d-success"}),_vm._v(" "),_c('strong',[_vm._v(_vm._s(_vm.selectedEvaluation))])])]),_c('div',{staticClass:"clearfix"}),_c('v-data-table',{attrs:{"headers":_vm.headers,"hide-default-footer":"","options":_vm.pagination,"items":_vm.form.items.data,"footer-props.items-per-page-options":"rowsPerPageItems","loading":_vm.form.loading},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(_vm._s(index + _vm.form.items.meta.from))]),_c('td',{staticClass:"text-xs-left"},[_c('strong',[_vm._v(" "+_vm._s(item.subject.name)+" ")]),_c('small',{staticStyle:{"color":"#999"}},[_c('strong',[_vm._v(_vm._s(item.subject.sub_type === "opt" ? "(Optional)" : "")+" ")])]),_c('br'),(item.theory || item.practical)?_c('small',[_c('span',{class:_vm.totalSubjectFlag(
                          _vm.form.items.data[index].theory,
                          _vm.form.items.data[index].practical
                        )
                          ? 'highlights'
                          : ''},[_vm._v("Total Mark - "),_c('strong',[_vm._v(" "+_vm._s(_vm.total( _vm.form.items.data[index].theory, _vm.form.items.data[index].practical )))])])]):_vm._e()]),_c('td',{staticClass:"text-right"},[(
                      _vm.$auth.can('subject-mark-update&subject-mark-create')
                    )?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.items.data[index].theory),expression:"form.items.data[index].theory"}],staticClass:"marks_entry",class:_vm.totalSubjectFlag(
                        _vm.form.items.data[index].theory,
                        _vm.form.items.data[index].practical
                      )
                        ? 'borderRed'
                        : '',attrs:{"type":"number","autofocus":index === 0,"autocomplete":"off","disabled":_vm.is_publish},domProps:{"value":(_vm.form.items.data[index].theory)},on:{"focus":function($event){return $event.target.select()},"change":function($event){_vm.changed = true},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form.items.data[index], "theory", $event.target.value)}}}):_vm._e()]),_c('td',{staticClass:"text-right"},[(
                      _vm.$auth.can('subject-mark-update&subject-mark-create')
                    )?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.items.data[index].practical),expression:"form.items.data[index].practical"}],staticClass:"marks_entry",class:_vm.totalSubjectFlag(
                        _vm.form.items.data[index].theory,
                        _vm.form.items.data[index].practical
                      )
                        ? 'borderRed'
                        : '',attrs:{"autocomplete":"off","disabled":_vm.is_publish,"type":"number"},domProps:{"value":(_vm.form.items.data[index].practical)},on:{"change":function($event){_vm.changed = true},"focus":function($event){return $event.target.select()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form.items.data[index], "practical", $event.target.value)}}}):_vm._e()])])]}}])},[_c('v-alert',{attrs:{"slot":"no-results","value":true,"color":"error","icon":"warning"},slot:"no-results"},[_vm._v(" Your search for \""+_vm._s(_vm.search)+"\" found no results. ")])],1),_c('br'),(_vm.is_publish === false && !_vm.examPublishAlert)?_c('v-container',{staticStyle:{"margin-top":"-20px"}},[(!_vm.dataSubmit)?_c('alert-message',{attrs:{"extraSmall":"","size":"small","type":"warning","title":"Subject Mark Alert","message":"Before saving subject's mark, please make sure you have updated and verified all the subject that are and aren't listed for exams, Subject mark must not be greater than final full mark defined for subject"}}):_vm._e(),(_vm.dataSubmit && _vm.form.items.data && _vm.form.items.data.length)?_c('alert-message',{attrs:{"extraSmall":"","size":"small","type":"error","title":"Please review your marks","message":"Theory + Practical should be exceed 100, Marks should not be 0 or empty Practical should not be greater than theory"}}):_vm._e()],1):_vm._e(),(_vm.form.items.data.length)?_c('v-card-title',[(
                _vm.$auth.can('subject-mark-update&subject-mark-create') &&
                  _vm.is_publish === false
              )?_c('v-btn',{staticStyle:{"margin-bottom":"5px"},attrs:{"loading":_vm.saving,"outlined":_vm.form.is_update,"color":_vm.form.is_update ? 'warning' : 'primary',"block":"","large":"","disabled":_vm.dataSubmit || _vm.form.items.data.length < 1 || !_vm.changed || _vm.saving},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.form.is_update ? "Update" : "Save")+" ")]):_vm._e()],1):_vm._e(),_c('div',{class:'inner-overlay ' + (_vm.examPublishAlert ? '' : 'dont-show')},[_c('p',[_c('v-icon',{staticStyle:{"font-size":"50px","color":"red"}},[_vm._v("warning")]),_c('br'),_vm._v(" Exam has already been published. "),_c('br'),_c('span',{staticStyle:{"font-size":"15px"}},[_c('a',{attrs:{"href":""},on:{"click":function($event){$event.preventDefault();_vm.examPublishAlert = false}}},[_vm._v("Click Here")]),_vm._v(" to view the marks.")])],1)])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }